<script>
import AppIcon from "@/features/general/Icons";
import AppSvg from "@/features/general/Svg";
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import appNotFoundTracking from "./not-found-tracking.vue";

export default {
  components: {
    AppIcon,
    AppSvg,
    appNotFoundTracking,
  },

  created() {
    this.getData();
  },

  data() {
    return {
      trackingInfo: {
        sending_code: "",
        items: [],
      },
      notFoundTracking: false,
    };
  },

  computed: {
    ...mapGetters(["mainLoading", "isMobile", "storeInfo", "langs"]),
    ...mapState({
      list: (state) => state.store_info.list,
    }),

    /**
     * Verifica se há mais de um item na lista do tracking
     * @return {boolean}
     */
    isTrackingFirstStep() {
      return this.trackingInfo?.items?.length <= 1;
    },

    /**
     * Verifica se é o primeiro item da lista e se foi
     * aberto em um dispositivo mobile
     *
     * @return {boolean}
     */
    firstItemMobile() {
      if (this.isMobile && !this.isTrackingFirstStep) {
        return true;
      }
      return false;
    },

    /**
     * Verifica se a data deve aparecer a esqueda do ponto
     * verde ou a baixo do texto a direita
     *
     * @return {boolean}
     */
    showDateOnLeft() {
      if (!this.isMobile) {
        return true;
      } else if (this.isMobile && this.isTrackingFirstStep) {
        return true;
      } else {
        return false;
      }
    },

    /**
     * Valida se ja o produto ja doi entregue e escolhe uma classe de estilo
     * @return {string}
     */
    isCompletedTracking() {
      return this.trackingInfo.items[0].complete
        ? "app__tracking__first-bold"
        : "app__tracking__second-bold";
    },
  },

  methods: {
    ...mapActions("Customer", {
      setCustomerData: "setData",
    }),

    ...mapActions([
      "setStoreInfo",
      "setLoading",
      "setIsMobile",
      "setCustomStyle",
      "setGeneralError",
      "setLangs",
    ]),

    /**
     * Faz o get das informações do rastreio
     * @return {undefined}
     */
    async getData() {
      try {
        const { data } = await this.$http.get(
          `/tracker/${this.$route.params.storeId}/${this.$route.params.accessCode}`
        );

        if (data.tracking.items.length) {
          this.trackingInfo = data.tracking;
          return;
        }

        this.trackingInfo = {
          sending_code: data.tracking.sending_code,
          items: [
            {
              date: moment(),
              status: this.langs.shipping["preparing_order"],
              delivered: false,
              complete: false,
            },
          ],
        };
      } catch (error) {
        this.notFoundTracking = true;
      }
    },

    /**
     * Formata a data que vem da API
     *
     * @param {string} date texto da API com a data
     * @return {string}
     */
    formatDate(date) {
      const parseDate = moment(date);
      parseDate.locale("pt-br");
      return parseDate.format("DD MMM YYYY");
    },

    /**
     * Formata o local do produto que vem da API
     *
     * @param {string} local texto da API com o local
     * @return {string}
     */
    formatLocal(local) {
      if (local) {
        const cityLocal = local.split("-")[1];
        return cityLocal.length > 3 ? cityLocal : "";
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="app__dashboard__header" :class="{ app__loading: mainLoading }">
      <h1 class="app__dashboard__title app__dashboard__title--1">
        {{ trackingInfo.sending_code }}
      </h1>
    </div>
    <app-not-found-tracking v-if="this.notFoundTracking" />
    <div
      v-else
      class="container app__tracking__container"
      :class="{
        app__tracking__row__shadow: isMobile,
        app__dashboard__order: !isMobile,
        app__loading: mainLoading,
      }"
    >
      <h2
        class="app__dashboard__title app__dashboard__title--1 app__tracking__title"
      >
        {{ this.langs.shipping["track_product_delivery"] }}
      </h2>
      <table class="table table-borderless justify-content-center d-flex">
        <tbody
          class="app__tracking app__tracking"
          :class="{
            'app__tracking__row--first-step': isTrackingFirstStep,
          }"
        >
          <tr
            class="app__tracking__row"
            :class="{
              'app__tracking__row--closed-step': item.complete,
              'app__tracking__row__is-mobile': firstItemMobile,
            }"
            v-for="(item, index) in trackingInfo?.items"
            :key="index"
          >
            <td class="app__tracking__row__date-time" v-show="showDateOnLeft">
              <span class="app__tracking__date-city">{{
                formatDate(item.date)
              }}</span>
              <span class="app__tracking__date-city">{{
                formatLocal(item.local)
              }}</span>
            </td>
            <td>
              <div
                class="align-middle app__tracking__dot"
                :class="{
                  'app__tracking__green-dot': item.complete,
                }"
              />
            </td>
            <td :class="{ 'd-flex': !isMobile }">
              <span
                class="app__tracking__status"
                :class="isCompletedTracking"
                >{{ item.status }}</span
              >
              <div v-show="isMobile">
                <span
                  v-show="!isTrackingFirstStep"
                  class="app__tracking__date-city"
                  >{{ formatDate(item.date) }}</span
                >
                <span class="app__tracking__date-city">{{
                  formatLocal(item.local)
                }}</span>
              </div>
            </td>
          </tr>
          <tr
            class="app__tracking__row app__tracking__row--first-step"
            v-show="isTrackingFirstStep"
          >
            <td class="app__tracking__row__date-time" v-show="!isMobile" />
            <td>
              <div class="align-middle app__tracking__dot" />
            </td>
            <td />
          </tr>
        </tbody>
      </table>

      <section
        v-show="isTrackingFirstStep"
        :class="{ app__loading: mainLoading }"
      >
        <div
          :class="
            isMobile
              ? 'app__tracking__helper__is-mobile'
              : 'app__tracking__helper'
          "
        >
          <app-icon prefix="fa" name="info-circle" class="app__icon--lg" />
          <p
            class="app__tracking__helper__text"
            :class="{ app__loading: mainLoading }"
          >
            {{ this.langs.shipping["tracking_code_no_records"] }}
          </p>
        </div>
        <div
          :class="
            isMobile
              ? 'app__tracking__helper__is-mobile'
              : 'app__tracking__helper'
          "
        >
          <app-svg class="app__tracking__helper__icon" name="question-circle" />
          <p
            class="app__tracking__helper__text"
            :class="{ app__loading: mainLoading }"
          >
            {{ this.langs.shipping["questions_about_your_order"] }}<br />
            <a
              :href="`${this.list.uri.https}/contato`"
              :class="{ app__loading: mainLoading }"
            >
              {{ this.langs.shipping["click_here"] }}
            </a>
            {{ this.langs.shipping["get_in_touch_call_center"] }}
          </p>
        </div>
      </section>
    </div>
  </div>
</template>
