<script>
import { mapGetters, mapState } from "vuex";
import AppSvg from "@/features/general/Svg";

export default {
  name: "AppNotFoundTracking",
  computed: {
    ...mapGetters(["isMobile", "langs", "mainLoading"]),
    ...mapState({
      store_info: (state) => state.store_info.list,
    }),
  },
  components: {
    AppSvg,
  },
};
</script>

<template>
  <div
    class="container app__tracking__not-found app__tracking__container"
    :class="{
      app__tracking__row__shadow: isMobile,
      app__dashboard__order: !isMobile,
    }"
  >
    <app-svg class="app__tracking__not-found__image" name="curious-woman" />
    <p
      class="app__tracking__not-found__title"
      :class="{ app__loading: mainLoading }"
    >
      {{ this.langs.shipping["data_not_be_found"] }}
    </p>
    <p
      class="app__tracking__not-found__text"
      :class="{ app__loading: mainLoading }"
    >
      {{ this.langs.shipping["page_unavailable_or_removed"] }}
    </p>
    <a
      :href="`${this.store_info.uri.https}/contato`"
      class="app__tracking__not-found__link"
      :class="{ app__loading: mainLoading }"
    >
      {{ this.langs.shipping["call_center"] }}
    </a>
  </div>
</template>
